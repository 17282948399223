import React, { useState, useEffect } from 'react'

const DocumentHeaderReasonToConciliate = ({
  reasonsConciliationExpense 
}) => {
  const [reasonToConciliate, setReasonToConciliate] = useState([])


  const translateReason = (reason) => {
    switch (reason) {
      case 'billing_not_sent':
        return 'No se ha recibido la boleta'
      case 'without_documents':
        return 'No se han adjuntado documentos'
      case 'exceeds_min_conciliation_value':
        return 'Excede el valor mínimo de conciliación'
      default:
        return reason
    }
  }

  useEffect(() => {
    const parseReasons = () => {
      try {
        const parsedReasons = JSON.parse(reasonsConciliationExpense)
        
        // Si es un array de objetos con code y message
        if (Array.isArray(parsedReasons) && parsedReasons[0]?.message) {
          return parsedReasons.map(reason => reason.code)
        }
        
        // Si es un array de strings
        if (Array.isArray(parsedReasons)) {
          return parsedReasons
        }

        return []
      } catch (error) {
        console.error('Error parsing reasons:', error)
        return []
      }
    }

    setReasonToConciliate(parseReasons())
  }, [reasonsConciliationExpense])

  return (
    <div className="conciliation-reasons">
      <h3 className="conciliation-reasons__title">Motivos de conciliación</h3>
      <ul className="conciliation-reasons__list">
        {reasonToConciliate?.map((reason, index) => (
          <li key={index} className="conciliation-reasons__item">
            {index + 1}. {translateReason(reason)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DocumentHeaderReasonToConciliate