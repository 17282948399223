import React, {useState} from 'react'
import Label from '../../../../../../shared/Label'
import Select from 'react-select'

const DocumentHeaderDocumentType = (
  {
    document,
    setDocument,
    index,
    documentTypeOptions,
    isModal,
    isEditable
  }
) => {

  const [selectedOption, setSelectedOption] = useState({value: document.documentType, label: document.documentTypeName})

  console.log(documentTypeOptions)

  const getDocumentTypeGroup = (value) => {
    let resp
    documentTypeOptions.forEach((documentTypeGroup, index) => {
      documentTypeGroup.options.forEach((documentTypeOption) => {
        if (documentTypeOption.value === value) {
          switch (index) {
            case 0:
              resp = 'bonus'
              break;
            case 1:
              resp = 'refund'
              break;
            case 2:
              resp = 'without_prevision'
              break;
          }
        }
      })
    })
    return resp
  }

  const handleChange = (selected) => {
    let newDocument = document
    newDocument.documentType = selected.value
    newDocument.documentTypeName = selected.label
    newDocument.documentTypeGroup = getDocumentTypeGroup(selected.value)
    setDocument(index, newDocument, true, newDocument.canBeEvaluated, 100)
    setSelectedOption(selected)
  }

  const getOptions = () => {
    let options = documentTypeOptions
    if ((isModal && document.isAccountingDocument) || window.insurerIsPrimaryInsurance) {
      // Dejamos solo opciones de lineas sin prevision
      options = options.filter((optionGroup, index) => {
        return index === 2
      })
    }
    return options
  }

  return (
    <>
      <div className={'row'}>
        <Label htmlFor={'document_header_document_type_' + index} className={'col-12'}>Tipo de documento</Label>
        <div className={'col-12'}>
          {isEditable ? (<Select
            id={'document_header_document_type_' + index}
            value={selectedOption}
            onChange={handleChange}
            options={getOptions()}
            classNamePrefix={'react-select'}
          />) : (
          <Label className={'form-control'}>{selectedOption.label}</Label>
          )}
        </div>
      </div>
    </>
  )
}

export default DocumentHeaderDocumentType