import React, {useEffect, useState} from 'react'
import StepContainer from '../../../../shared/StepContainer'
import Documents from './Documents'
import HealthFormActionButtons from './HealthFormActionButtons'
import showLoading from '../../../../utils/showLoading'
import axios from 'axios'
import {
  documentTypesApiUrl,
  prescriptionsApiUrl
} from '../../../../routes/search'
import closeAlert from '../../../../utils/closeAlert'
import CardHeaderTitle from '../../../../shared/CardHeaderTitle'
import RequirementFormFooter from '../form_footer'
import ConciliationFooter from '../form_footer/ConciliationFooter'
import Diagnostics from './Diagnostics'
import Swal from "sweetalert2";

const HealthForm = (
  {
    requirement,
    updateRequirement,
    formAlerts,
    setFormAlerts,
    protectedFiles,
    documents,
    setDocuments,
    setShowBackgroundsModal,
    setShowBackgroundsConciliationModal,
    setShowRejectConciliationModal,
    setSendAssignConciliation,
    holdBackRequirementCallback,
    saveRequirementCallback,
    finishRequirementCallback,
    approveRequirementConcilation,
    rejectRequirementConcilation,
    auditRequirementCallback,
    healthProvisionTypes,
    setDiagnostic,
    diagnostic,
    setDiagnosticComment,
    diagnosticComment,
    insuree,
    policy,
    insurerProblemProviders,
    policyId,
    requirementType, // Parámetro para mostrar el form con los campos de salud o dental V2
    toothOptions,
    sideOptions,
    isEditable,
    letterTypes,
    isFullInsurer,
    canConciliate,
    showAssignConciliationBtn,
    showConciliationFooter,
    showModalConciliationDocuments,
    isConciliationExpense,
    reasonsConciliationExpense,
    fromWsEvaluation,
    currentUserId
  }
) => {


  const [documentTypeOptions, setDocumentTypeOptions] = useState([])
  const [getDocumentTypeOptions, setGetDocumentTypeOptions] = useState(true)
  const insurerId = window.insurerId
  const requirementId = window.requirementId
  const [getWithDecimals, setGetWithDecimals] = useState(true)
  const [prescriptions, setPrescriptions] = useState([])
  const [getPrescriptions, setGetPrescriptions] = useState(true)
  const [triggeredAlerts, setTriggeredAlerts] = useState([])

  const {
    initialProtectedFiles,
    backgroundOptions,
    countryIso,
    providerBackgroundResponses
  } = requirement

  const getPossibleFiles = () => {
    return [...initialProtectedFiles, ...protectedFiles]
  }
  const getDocumentTypeOptionsRequest = async () => {
    let formData = new FormData()
    formData.append('insurer_id', insurerId)
    showLoading()
    await axios({
      url: documentTypesApiUrl,
      method: 'POST',
      data: formData
    })
    .then ((res) => {
      let data = res.data
      setDocumentTypeOptions(data)
      setGetDocumentTypeOptions(false)
    })
    .catch(err =>{
      // Error
      console.log(err)
      setGetDocumentTypeOptions(true)
    })
    .finally(() => {
      closeAlert()
    })
  }

  const getPatientPrescriptions = async () => {
    let formData = new FormData()
    formData.append('insurer_id', insurerId)
    formData.append('requirement_id', requirementId)
    showLoading()
    await axios({
      url: prescriptionsApiUrl,
      method: 'POST',
      data: formData
    })
      .then ((res) => {
        let data = res.data
        setPrescriptions(data)
        setGetPrescriptions(false)
      })
      .catch(err =>{
        // Error
        console.log(err)
        setGetPrescriptions(true)
      })
      .finally(() => {
        closeAlert()
      })
  }

  const setWithDecimals = () => {
    window.withDecimals = false
    setGetWithDecimals(false)
  }

  const alreadyTriggeredAlert = (instancePolicyId, providerId) => {
    return triggeredAlerts.filter((alert) => alert.instancePolicyId === instancePolicyId && alert.providerId === providerId).length > 0
  }

  const triggerPolicyInstanceProvisionAlert = (instancePolicyId, providerId, message, alertType) => {
    if (!alreadyTriggeredAlert(instancePolicyId, providerId)) {
      let newTriggeredAlert = {
          instancePolicyId: instancePolicyId,
          providerId: providerId,
          message: message
        }
      setTriggeredAlerts([... triggeredAlerts, newTriggeredAlert])
      setTimeout(() => {
        Swal.fire({
          icon: alertType,
          text: message,
          confirmButtonColor: '#0281c4'
        })
      }, 1000)
    }
  }

  useEffect(() => {
    if (documentTypeOptions.length === 0 && getDocumentTypeOptions) {
      getDocumentTypeOptionsRequest()
    }
    if (getWithDecimals) {
      setWithDecimals()
    }
    if (getPrescriptions) {
      getPatientPrescriptions()
    }
  }, [documentTypeOptions, getDocumentTypeOptions, getWithDecimals, getPrescriptions])
  return (
    <>
      <div className={'expense-form__health row pt-4'}>
        {requirementType == "health" &&
          <StepContainer
            className={'expense-form__health-diagnostic'}
            cardHeader={<CardHeaderTitle title={(requirement.hasRejectDetails ? '5' : '4') + ' - Diagnóstico'}/>}
            containerName={'diagnostic'}
            formAlerts={formAlerts}
          >
            <Diagnostics 
            {...
              {
                requirement,
                setDiagnostic,
                diagnostic,
                setDiagnosticComment,
                diagnosticComment,
                isEditable
              }
            }
            />
          </StepContainer>
        }
        <StepContainer
          className={'expense-form__health-documents'}
          cardHeader={<CardHeaderTitle title={(requirement.hasRejectDetails ? '6' : '5') + ' - Documentos'}/>}
          containerName={'documents'}
          containerClassName={'pl-3 pr-3'}
          formAlerts={formAlerts}
          cardFooter={
            isEditable ? (
              <HealthFormActionButtons
                {...
                  {
                    documents,
                    setDocuments,
                    documentTypeOptions
                  }
                }
              />
            ) : null 
          }
        >
          <Documents
            {...
              {
                formAlerts,
                setFormAlerts,
                documents,
                setDocuments,
                documentTypeOptions,
                getPossibleFiles,
                backgroundOptions,
                prescriptions,
                setPrescriptions,
                healthProvisionTypes,
                policyId,
                insuree,
                policy,
                triggerPolicyInstanceProvisionAlert,
                insurerProblemProviders,
                requirementType,
                toothOptions,
                sideOptions,
                countryIso,
                isEditable,
                isFullInsurer,
                providerBackgroundResponses,
                showModalConciliationDocuments,
                isConciliationExpense,
                reasonsConciliationExpense
              }
            }
          />
        </StepContainer>
      </div>
      <RequirementFormFooter
        {...
          {
          requirement,
          updateRequirement,
          setShowBackgroundsModal,
          holdBackRequirementCallback,
          saveRequirementCallback,
          finishRequirementCallback,
          auditRequirementCallback,
          isEditable,
          letterTypes,
          isFullInsurer,
          canConciliate,
          showAssignConciliationBtn,
          showModalConciliationDocuments,
          setSendAssignConciliation,
        }
      }
      />
      {!isEditable && canConciliate && showConciliationFooter && (
      <ConciliationFooter
        {...
          {
          requirement,
          updateRequirement,
          setShowBackgroundsConciliationModal,
          setShowRejectConciliationModal,
          holdBackRequirementCallback,
          saveRequirementCallback,
          finishRequirementCallback,
          auditRequirementCallback,
          approveRequirementConcilation,
          rejectRequirementConcilation,
          isEditable,
          isFullInsurer
        }
        }
        />
      )}
    </>
  )
}

export default HealthForm