import React from 'react'
import DocumentContainer from './document_container'

const Documents = (
  {
    formAlerts,
    setFormAlerts,
    documents,
    setDocuments,
    documentTypeOptions,
    getPossibleFiles,
    backgroundOptions,
    prescriptions,
    setPrescriptions,
    healthProvisionTypes,
    policyId,
    insuree,
    policy,
    triggerPolicyInstanceProvisionAlert,
    insurerProblemProviders,
    requirementType,
    toothOptions,
    sideOptions,
    countryIso,
    isEditable,
    isFullInsurer,
    providerBackgroundResponses,
    showModalConciliationDocuments,
    isConciliationExpense,
    reasonsConciliationExpense
  }
) => {

  const removeDocument = (key) => {
    let filteredArray = documents.filter((item) => {
        return key !== item.systemId
      })
    setDocuments(filteredArray, true, 100)
  }

  const setDocument = (key, value, forceUpdate, newEvaluation, sleepTime) => {
    const newArray = documents.map((item) => {
      if (key === item.systemId) {
        if (forceUpdate !== false) {
          value.changeKey()
        }
        return value
      }
      return item
    })
    setDocuments(newArray, newEvaluation, sleepTime)
  }

  const getDocumentCount = () => {
    return documents.length
  }

  return (
    <>
    {getDocumentCount() > 0 ? (
      <>
        {documents.map((document) => (
          <DocumentContainer
            {...
              {
                document,
                setDocument,
                removeDocument,
                documentTypeOptions,
                formAlerts,
                setFormAlerts,
                backgroundOptions,
                getPossibleFiles,
                prescriptions,
                setPrescriptions,
                documents,
                setDocuments,
                healthProvisionTypes,
                policyId,
                insuree,
                policy,
                countryIso,
                triggerPolicyInstanceProvisionAlert,
                insurerProblemProviders,
                requirementType,
                toothOptions,
                sideOptions,
                isFullInsurer,
                providerBackgroundResponses,
                showModalConciliationDocuments,
                isConciliationExpense,
                reasonsConciliationExpense
              }
            }
            isModal={false}
            index={document.systemId}
            attachments={getPossibleFiles()}
            key={document.key}
            isEditable={isEditable}
          />
        ))}
      </>
    ) : ( 
      <p className={'empty_content'}>No hay documentos</p>
    )}
      
    </>
  )
}

export default Documents